<template>
  <a-drawer
    title="修改项目"
    :maskClosable="false"
    width="750"
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="projectEditVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form :form="form">
      <a-form-item label="合同号" v-bind="formItemLayout">
        <a-input
          style="width: 100%"
          v-decorator="[
            'contractNo',
            {
              rules: [
                { required: true, message: '不能为空' },
                { max: 20, message: '长度不能超过20个字符' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="项目名称" v-bind="formItemLayout">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [
                { required: true, message: '不能为空' },
                { max: 255, message: '长度不能超过255个字符' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="产品线" v-bind="formItemLayout">
        <a-select
          @change="onProductLineChange"
          v-decorator="[
            'productLine',
            { rules: [{ required: true, message: '请选择产品线' }] },
          ]"
        >
          <a-select-option
            v-for="line in productLineData"
            :key="line"
            :value="line"
            >{{line }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="产品型号" v-bind="formItemLayout">
        <a-select
          v-decorator="[
            'productVersion',
            { rules: [{ required: true, message: '请选择产品型号' }] },
          ]"
        >
          <a-select-option
            v-for="version in productVersionArray"
            :key="version"
            :value="version"
            >{{version }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="数量" v-bind="formItemLayout">
        <a-input-number
          :min="1"
          v-decorator="[
            'amount',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="标准服务天数" v-bind="formItemLayout">
        <a-input-number
          :min="0"
          v-decorator="['standardServiceDays',{},]"
        />
      </a-form-item>
      <a-form-item label="发货日期" v-bind="formItemLayout">
        <a-date-picker
          valueFormat="yyyy-MM-dd hh:mm:ss"
          v-decorator="[
            'actualDate',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="SAP订单号" v-bind="formItemLayout">
        <a-input
          v-decorator="[
            'sapNo',
            { rules: [
              { required: true, message: '不能为空' },
              { max: 50, message: '长度不能超过50个字符' }
            ] },
          ]"
        />
      </a-form-item>
      <a-form-item label="工厂" v-bind="formItemLayout">
        <a-input
          v-decorator="[
            'factory',
            {
              rules: [
                { required: true, message: '不能为空' },
                { max: 255, message: '长度不能超过255个字符' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="状态" v-bind="formItemLayout">
        <a-radio-group
          v-decorator="[
            'status',
            { rules: [{ required: true, message: '请选择状态' }] },
          ]"
        >
          <a-radio :key="key" :value="val.keyy" v-for="(val, key) in status">{{
            val.valuee
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="项目地址" required v-bind="formItemLayout">
        <v-distpicker
          @selected="onSelected"
          :province="project.province"
          :city="project.city"
          :hideArea="true"
        ></v-distpicker>
        <a-input placeholder="现场地址" v-decorator="['address',{},]" />
      </a-form-item>
      <a-form-item label="二维码" v-bind="formItemLayout">
        <div id="printContent" style="display: inline-block;">
          <qr 
          :qrUrl="'projectId=' + project.projectId + '&&\n请使用微信小程序“伊顿电力客户服务”扫码获取服务'"
          qrText="请使用“伊顿电力客户服务”微信小程序服务扫码"
          @getUrl="getQrcodeUrl"></qr>
          <!-- <vue-qr
              ref="Qrcode"
              :text="'projectId=' + project.projectId + '&&\n请使用微信小程序“伊顿电力客户服务”扫码获取服务'"
              logoSrc="eaton-online-small-icon.png"
              :download="downloadFilename"
              :margin="20"
              :size="200"
              :dotScale="qrCodeConfig.dotScale"
              :colorDark="qrCodeConfig.colorDark"
          >
          </vue-qr>
          <div>使用“伊顿电力客户服务”微信小程序</div> -->
        </div>
        <a-button type="dashed" @click="downloadQrCode" style="margin-right: 10px;">下载</a-button>
        <a-button type="dashed" @click="printPreview(1)">打印</a-button>
      </a-form-item>
    </a-form>

    <div class="drawer-bootom-button">
			<a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment";
import vueQr from "vue-qr";
import { getLodop } from './LodopFuncs'
import Qr from "../../../components/qr/qr"

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const productLineData = ['VCB', 'Assy', 'RMU', 'Others'];
const productVersionData = {
  VCB: ['VS1', 'E-VAC', '3AS4', '3AS2', 'VCP', 'VE24', 'SL', '405W-VAC', 'W-VACi'],
  Assy: ['ET1', 'UX', 'MV starter', 'DX', 'Xboard', 'VFD'],
  RMU: ['Xiria', 'Xiria-Plus'],
  Others: ['Others'],
};

export default {
  name: "projectEdit",
  components: { vueQr, Qr },
  props: {
    projectEditVisiable: {
      default: false,
    },
    status: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      formItemLayout,
      productLineData,
      productVersionData,
      productVersionArray: [],
      form: this.$form.createForm(this),
      project: {},
      qrCodeConfig: {
        text: "projectId=",
        colorDark: "#663300",
        colorLight: "#fff"
      },
      downloadFilename: "",
      qrcodeUrl: "", 
    };
  },
  methods: {
    printPreview(s) {
      const LODOP = this.CreateOneFormPage()
      if (s == 0) {
        LODOP.PRINT() //直接打印
      }
      if (s == 1) {
        LODOP.PREVIEW() //打印预览
      }
      if (s == 2) {
        LODOP.PRINT_SETUP() //打印维护
      }
      if (s == 3) {
        LODOP.PRINT_DESIGN() //打印设计
      }
    },
    CreateOneFormPage() {
      const LODOP = getLodop()
      //样式
      var olstyle1 = `<style>body { display: flex; justify-content: center; align-content: center; width: 200px;height: 200px; } img { width: 100%; height: 100%; }</style>`
      var body = olstyle1 + '<body>' + document.getElementById('printContent').innerHTML + '</body>'
      console.log(body);
      LODOP.PRINT_INIT('二维码') //打印初始化
      LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
      LODOP.SET_PRINT_STYLE('Bold', 1) // 设置对象风格
      // LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
      LODOP.SET_PRINT_PAGESIZE(0, 356, 356, '') //设定纸张大小
      LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '50%')//设置缩放
      // LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
      // 打印二维码
      // LODOP.ADD_PRINT_BARCODE(23,23,233,233,"QRCode","https://blog.csdn.net/qq_43652509");
      //打印网址
      // LODOP.ADD_PRINT_URL(222,2000,2000,233,"https://blog.csdn.net/qq_43652509");
      //打印图片
      // LODOP.ADD_PRINT_IMAGE(100,100,400,400,"<img border='0' src='http://s1.sinaimg.cn/middle/4fe4ba17hb5afe2caa990&690' width='345' height='250'>");
      LODOP.ADD_PRINT_HTM(0, 0, 2000, 2000, body) //增加超文本项
      return LODOP;
    },
    reset() {
      this.loading = false;
      this.form.resetFields();
    },
    onClose() {
      this.reset();
      this.$emit("close");
    },
    setFormValues({ ...project }) {
      let fields = [
        "contractNo",
        "name",
        "productLine",
        "productVersion",
        "amount",
        "standardServiceDays",
        "actualDate",
        "sapNo",
        "factory",
        "province",
        "city",
        "address",
        "status",
      ];
      Object.keys(project).forEach((key) => {
        if (fields.indexOf(key) !== -1) {
          this.form.getFieldDecorator(key);
          let obj = {};
          obj[key] = project[key];
          if (key === "actualDate") {
            obj[key] = moment(project[key]);
          }
          this.form.setFieldsValue(obj);
        }
      });
      this.project = project;
      this.productVersionArray = productVersionData[project.productLine] != undefined ? productVersionData[project.productLine] : []
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let project = this.form.getFieldsValue();
          if (this.project.province == "" || this.project.city == "" ) {
            this.$notification.warning({
              message: "警告提示",
              description: "省市为必填",
            });
            return;
          }
          project.projectId = this.project.projectId;
          project.province = this.project.province;
          project.city = this.project.city;
          project.actualDate = moment(project.actualDate).valueOf();
          this.$put("project", {
            ...project,
          })
            .then(() => {
              this.reset();
              this.$emit("success");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    onProductLineChange(value) {
      this.productVersionArray = productVersionData[value]
      this.form.setFieldsValue({productVersion: ''});
    },
    downloadQrCode() {
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = "project_" + this.project.projectId;
      a.href = this.qrcodeUrl;
      a.dispatchEvent(event);
    },
    getQrcodeUrl(url) {
      this.qrcodeUrl = url
    },
    onSelected(data) {
      this.project.province = data.province.value;
      this.project.city = data.city.value;
    },
  },
};
</script>
